import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyTextWithBackground } from "../../../slices/PageDataBodyTextWithBackground"

type Props = Partial<
	React.ComponentPropsWithoutRef<typeof PageDataBodyTextWithBackground>
>

const Component = (props: Props) => {
	return (
		<CmsGuideLayout>
			<PageDataBodyTextWithBackground
				richText={
					[
						{ type: "heading2", text: lorem.words(5), spans: [] },
						{ type: "paragraph", text: lorem.paragraphs(1), spans: [] },
					] as pt.RichTextField
				}
				link="#"
				linkText="Learn More"
				color="red"
				{...props}
			/>
		</CmsGuideLayout>
	)
}

export default Component
